import { APICore } from './apiCore';
import {ApiPublicAuthorization} from "./apiPublicAuthorization";

const api = new APICore();
export function createEventApi(params: {}) {
    const baseUrl = '/event/create/';
    return api.create(`${baseUrl}`, params);
}

export function getEventsApi(companyId: any) {
    const baseUrl = `/event/all?companyId=${companyId}`;
    return api.get(baseUrl, null);
}

export function getEventApi(id: string) {
    const baseUrl = `/event/${id}/detail`;
    return api.get(baseUrl, null);
}

export async function getPublicEventApi(id: string) {
    let ip = await api.getIp();
    const baseUrl = `/event/p/${id}?ip=${ip}`;
    return api.get(baseUrl, null);
}

export function createRsvpApi(data: any) {
    const baseUrl = '/event/rsvp/';
    return api.create(`${baseUrl}`, data);
}

export function checkoutApi(data: any) {
    const baseUrl = '/cart/checkout'
    return api.create(baseUrl, data);
}

export async function getCartApi(id: string, sessionId: string) {
    let ip = await api.getIp();
    const baseUrl = `/cart/checkout/${id}?baseUrl=${window.location.origin}&cartId=${sessionId}&ip=${ip}`;
    return api.get(baseUrl, null);
}

export function editEventApi(data: any) {
    const baseUrl = `/event/edit`
    return api.create(baseUrl, data)
}

export function eventCheckinApi(data: any) {
    const baseUrl = `/event/checkin`
    const newApi = new ApiPublicAuthorization();

    return newApi.create(baseUrl, data)
}

export async function updateCartApi(data: any) {
    let ip = await api.getIp();
    const baseUrl = `/cart/update?ip=${ip}`;
    return api.create(baseUrl, data);
}

export async function adminCartApi(data: any) {
    const baseUrl = `/event/tickets/`;
    return api.create(baseUrl, data);
}

export async function checkDiscountApi(data: any) {
    let ip = await api.getIp();
    const baseUrl = `/cart/discount?ip=${ip}`;
    return api.create(baseUrl, data);
}

export async function getRentalInfoApi(data: any) {
    const baseUrl = `/rentals/info`;
    return api.create(baseUrl, {ids: data});
}

export async function rentalCheckoutApi(data: any) {
    const baseUrl = `/rentals/checkout`;
    return api.create(baseUrl, data);
}

export async function pickupInfoEventApi(data: any) {
    const baseUrl = `/rentals/pickup`;
    return api.create(baseUrl, data);
}

export async function getRentalCheckinApi(data: any) {
    const baseUrl = `/rentals/checkin/${data.id}`;
    const newApi = new ApiPublicAuthorization();

    return newApi.create(baseUrl, data);
}

export async function checkoutRentalCheckinApi(data: any) {
    const baseUrl = `/rentals/rentalItems`;
    const newApi = new ApiPublicAuthorization();

    return newApi.create(baseUrl, data);
}

export async function rsvpSearchApi(data: any) {
    const baseUrl = `/event/rsvps`;
    return api.create(baseUrl, data);
}

export async function addToWaitlistApi(data: any) {
    let ip = await api.getIp();
    const baseUrl = `/event/waitlist?ip=${ip}`;
    return api.create(baseUrl, data);
}
