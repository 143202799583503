import config from '../../config';

class ApiPublicAuthorization {
    create = async (url: string, data: any) => {
        const response = await fetch(`${config.API_URL}${url}`, {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                Authorization: data.appCode
            }
        });

        const resp = await response.json();

        if (response.status !== 200)
            return Promise.reject(resp);

        return resp;
    };
}

export {ApiPublicAuthorization};
