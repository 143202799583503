import {useEffect, useRef} from "react";
import dayjs from "dayjs";

export const getMultipleTreeOfKeys = (itemKey: any, multiples: any) => {
    let keySet = [itemKey];

    multiples.forEach((multiple: any) => {
        const keysForParent = multiples.filter((m1: any) => keySet.includes(m1.parent));

        keySet = [...keySet, ...keysForParent.map((k1: any) => k1.key)];
    });

    return keySet.filter((item: any, pos: any) => (keySet.indexOf(item) === pos));
}

export const getMultipleTreeOfKeysWithParentKey = (parentKey: any, itemKey: any, multiples: any) => {
    let keySet = [itemKey, parentKey];

    multiples?.forEach((multiple: any) => {
        if (keySet.includes(multiple.key) || keySet.includes(multiple.parent))
            keySet = [...keySet, multiple.parent];
    });

    return keySet?.filter((item: any, pos: any) => (keySet.indexOf(item) === pos));
}

export const getMultipleTicketName = (multiple: any, multiples: any) => {
    const keys = getMultipleTreeOfKeysWithParentKey(multiple.parent, multiple.key, multiples);
    const items = multiples.filter((multi: any) => keys.indexOf(multi.key) > -1)
    return items.map((item: any) => item.name).join(' - ');
}

export const useDebounce = (callback: any, delay: any) => {
    const timeoutRef = useRef<any>(null);

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const debouncedCallback = (...args: any[]) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            callback(...args);
        }, delay);
    };

    return debouncedCallback;
};

export const findAllParentsAndChildren = (key: any, parent: any, multiples: any) => {
    const arrayOfKeysThatFitAsSetOfChildren: any = [];
    const arrayOfKeysThatFitAsSetOfParents: any = [];

    multiples.forEach((mtp: any) => {
        if (mtp.parent === key) {
            arrayOfKeysThatFitAsSetOfChildren.push(mtp.key);
            multiples.filter((m2: any) => m2.parent === mtp.key).forEach((key: any) => arrayOfKeysThatFitAsSetOfChildren.push(key.key))
        }
        if (mtp.parent === parent) {
            arrayOfKeysThatFitAsSetOfParents.push(mtp.parent);
            multiples.filter((m2: any) => m2.key === mtp.parent).forEach((filtered: any) => arrayOfKeysThatFitAsSetOfParents.push(filtered.parent))
        }
    });

    const fullSetOfChildren = multiples.filter((mtp: any) => arrayOfKeysThatFitAsSetOfChildren.indexOf(mtp.key) > -1);
    const fullSetOfParents = multiples.filter((mtp: any) => arrayOfKeysThatFitAsSetOfParents.indexOf(mtp.key) > -1);

    return [fullSetOfChildren, fullSetOfParents]
}

export const onLoadTicketEvent = (groups: any, event: any, setMultiTickets: any, setGroups: any) => {
    if (Object.keys(groups).length > 0) return;
    let _groups = {...groups};
    let multiples: any = {};
    let products: any = [];

    event.tickets?.forEach((ticket: any) => {
        if (ticket.multiple)
            multiples = getMultiple(ticket, multiples);

        if (ticket.parent) {
            _groups = {
                ..._groups,
                [ticket.groupName]: {
                    details: ticket.groupDetails,
                    name: ticket.groupName,
                    tickets: [ticket, ...event.tickets.filter((tix: any) => tix.hasParent && tix.parentKey === ticket.groupName.toLowerCase())]
                }
            }
        } else if (!ticket.parent && !ticket.hasParent) {
            _groups = {
                ..._groups,
                0: {
                    tickets: [...event.tickets.filter((tix: any) => !tix.hasParent && !tix.parent)]
                }
            }
        }
    });

    if (event.products?.length > 0) {
        products = event.products;
        _groups = {
            ..._groups,
            Products: {
                details: 'Addon Event Products Today!',
                name: 'Products',
                isProduct: true,
                tickets: products
            }
        }
    }

    setMultiTickets(multiples);

    setGroups(Object.keys(_groups).sort().reduce(
        (obj: any, key: any) => {
            obj[key] = _groups[key];
            return obj;
        },
        {}
    ));
}

export const getError = (ticket: any, errorSet: any, multiTickets: any, selectedProducts: any, event: any, range: any) => {
    if (!errorSet.length) return false;

    if (ticket.multiple) {
        const selected = findSelectedTicket(ticket, multiTickets);
        if (!selected) return false;
        const error = errorSet.find((error: any) => error.id === selected.id);
        if (!error) return false;
        return `${error.error} for ${selected.name} ticket.`;
    }

    if (ticket.options) {
        const selected = findSelectedProduct(ticket, selectedProducts, event);
        if (!selected) return false;
        const error = errorSet.find((error: any) => error.id === selected.id);
        if (!error) return false;
        return `${error.error}`
    }

    if (ticket.timeBased) {
        if (!range[ticket.id]?.checkInDate || !range[ticket.id]?.checkOutDate) return null;
        let standardError = errorSet.find((error: any) => error.id === `${ticket.id}_${dayjs(range[ticket.id]?.checkInDate).format('MM-DD-YYYY')}_${ticket.id}_${dayjs(range[ticket.id]?.checkOutDate).format('MM-DD-YYYY')}`);
        if (!standardError) return false;
        return `${standardError.error}`
    }

    let standardError = errorSet.find((error: any) => error.id === ticket.id);
    if (!standardError) return false;

    return standardError.error;
}

export const findSelectedTicket = (ticket: any, multiTickets: any) => {
    return ticket.multiples.find((tix: any) => tix.key === multiTickets[ticket.type].selectedKey);
}

export const getProductValue = (product: any, event: any, qty: any, selectedProducts: any) => {
    if (event.products) {
        const foundVariant = findSelectedProduct(product, selectedProducts, event);
        return qty.find((product: any) => product?.id === foundVariant?.id)?.val || null;
    }

    return null;
}

export const getTicketCost = (ticket: any) => {
    if (ticket.donation) {
        return ''
    }
    if (ticket.multiple) {
        const sortedValues: any = [
            ...new Set(ticket.multiples.filter((tix: any) => tix.parent).map((ticket: any) => ticket.price).filter((n: any) => n).filter((n: any) => n !== 0).sort())
        ];
        if (sortedValues.length === 0)
            return `$${parseFloat(ticket.multiples[0].price).toLocaleString()}`
        if (sortedValues.length < 2)
            return `$${parseFloat(sortedValues[0]).toLocaleString()}`
        return `$${sortedValues[0]} - $${sortedValues[sortedValues.length - 1]}  per ticket`
    }

    if (ticket.timeBased) {
        return `$${ticket.price} per night${ticket.passFees ? ' + fees' : ''}`
    }

    return `$${ticket.price} per ticket ${ticket.passFees ? ' + fees' : ''}`;
}

export const canSell = (saleStartDate: any) => {
    if (!saleStartDate) return true;
    return dayjs() >= dayjs(saleStartDate);
};

export const multiTicketSelect = (selected: any, ticket: any, nameSetSet: any, label: any, multiTickets: any, qty: any, setMultiTickets: any, handleTicketChange: any) => {
    const nameSet = nameSetSet.find((item: any) => item.key === selected);

    let resetSelected: any = [];
    let parentSetOfKeys: any = [nameSet.key];

    Object.keys(multiTickets[ticket.type]).forEach((key: any) => {
        if (key !== 'selectedKey')
            multiTickets[ticket.type][key]?.nameSet.forEach((multiOption: any) => {
                if (parentSetOfKeys.includes(multiOption.parent) && !resetSelected.includes(key)) {
                    parentSetOfKeys = [...parentSetOfKeys, multiOption.key]
                    resetSelected = [
                        ...resetSelected,
                        key
                    ]
                }
            })
    })

    let updatedTicketSettings = {
        ...multiTickets[ticket.type],
        selectedKey: (nameSet.limit >= 0) ? nameSet.key : null,
        [label]: {
            ...multiTickets[ticket.type][label],
            selected: nameSet.key
        }
    };

    resetSelected.forEach((key: any) => {
        updatedTicketSettings = {
            ...updatedTicketSettings,
            selectedKey: null,
            [key]: {
                ...updatedTicketSettings[key],
                selected: null
            }
        }
    });

    const multiSetUpdated = {
        ...multiTickets,
        [ticket.type]: updatedTicketSettings
    };

    if (resetSelected.length > 0 && qty[ticket.type]?.val && qty[ticket.type]?.val !== 0) {
        handleTicketChange(0, ticket, updatedTicketSettings);
    }

    setMultiTickets(multiSetUpdated);
}

export const getTicketValue = (ticket: any, multiTickets: any, qty: any, range: any) => {
    if (ticket.multiple) {
        const currentSelected = multiTickets[ticket.type].selectedKey;
        const multipleTicket = ticket.multiples.find((multi: any) => multi.key === currentSelected);
        return qty.find((tix: any) => tix.id === multipleTicket?.id)?.val || null;
    }
    if (ticket.timeBased) {
        if (!range[ticket.id]?.checkInDate || !range[ticket.id]?.checkOutDate) return null;
        const selectedCheckIn = ticket.displaySet.find((idData: any) => idData.day === range[ticket.id]?.checkInDate);
        const selectedCheckOut = ticket.displaySet.find((idData: any) => idData.day === range[ticket.id]?.checkOutDate);

        return qty.find((tix: any) => tix.id === `${selectedCheckIn.id}_${selectedCheckOut.id}`)?.val || null
    }
    return qty.find((tix: any) => tix.id === ticket.id)?.val || null;
}

export const isTimeBasedSoldOut = (displaySet: any) => {
    let soldOut = false;

    displaySet.forEach((item: any, index: any) => {
        if ((index + 1) + item.minStay < displaySet.length) {
            for (let i = (index + 1); i < item.minStay; i++) {
                if (displaySet[i].remaining < 1) {
                    soldOut = true;
                    break;
                }
            }
        }
    });

    return soldOut;
}

export const disabledDate = (current: any, ticket: any, range: any) => {
    const ticketRange = ticket.timeRange;
    const startDate = dayjs(ticketRange[0]).startOf('day');
    const endDate = dayjs(ticketRange[1]).endOf('day');
    const selectedStartDate = range[ticket.id]?.checkInDate || null;
    const minStay = ticket.minStay;
    const indexOfTicket = ticket.displaySet.findIndex((tix: any) => tix.day === current.format('YYYY-MM-DD'));
    const ticketInBunch = ticket.displaySet[indexOfTicket];

    if ((ticketInBunch && !ticketInBunch.lastDay && ticketInBunch.remaining < 1) || ticket.displaySet[indexOfTicket + 1]?.remaining < 1)
        return true;

    if (selectedStartDate && minStay) {
        if (current && current < startDate) return true;
        if (current && current > endDate) return true;

        return current && (current < dayjs(selectedStartDate).add(minStay, 'days'));
    } else if (!selectedStartDate && minStay) {
        if (current && current < startDate) return true;
        if (current && current > endDate) return true;

        return current && (current > endDate.subtract(minStay, 'days'));
    }

    return !(current.isBetween(startDate, endDate, 'day', '[)'));
};

export const timeBasedTicketSelect = (date: any, ticket: any, type: any, range: any, setRange: any) => {
    let updatedRange = {
        ...range,
        [ticket.id]: {
            ...(range[ticket.id] || {}),
            [type]: date
        }
    };

    setRange(updatedRange);
}

export const openNotification = (key: any, message: any, api: any) => {
    api.info({
        key,
        message,
        placement: 'bottomRight',
        closeIcon: false,
    });
};

export const handleTicketChangeEvent = (e: any, qty: any, ticket: any, setQty: any, multiTickets: any, range: any, api: any, multipleOverride?: any) => {
    const ticketValue = e < 0 ? 0 : e;
    let updatedSet: any = [...qty];

    let cost = ticket.price;

    let updatedValue: any = {
        name: ticket.type,
        val: ticketValue,
        id: ticket.id,
        ewizFee: ticket.ewizFee,
        parentId: ticket.id,
        baseId: ticket.id,
        passFees: ticket.passFees,
        taxable: ticket.taxable,
        remove: ticket.remove,
    };

    if (ticket.multiple) {
        const selected = findSelectedTicket(ticket, multiTickets);
        const ticketName = getMultipleTicketName(selected, ticket.multiples);
        cost = selected.cost;

        updatedValue = {
            ...updatedValue,
            multiples: multipleOverride ? multipleOverride : multiTickets[ticket.type],
            name: `${ticket.type} - ${ticketName} - @ $${selected.cost}`,
            ewizFee: selected.ewizFee,
            id: selected.id,
            limitId: selected.limitId,
        }
    }

    if (ticket.timeBased) {
        const ticketDates = range[ticket.id] || ticket.ticketDates;

        const nightsStaying = Number(dayjs(ticketDates.checkOutDate).diff(dayjs(ticketDates.checkInDate), 'days'));
        const selectedCheckIn = ticket.displaySet.find((idData: any) => idData.day === ticketDates.checkInDate);
        const selectedCheckOut = ticket.displaySet.find((idData: any) => idData.day === ticketDates.checkOutDate);

        cost = ticket.cost * nightsStaying;

        updatedValue = {
            ...updatedValue,
            name: `${ticket.type} - ${ticketDates.checkInDate} to ${ticketDates.checkOutDate} - ${nightsStaying} night(s)`,
            ewizFee: ticket.ewizFee,
            checkInId: selectedCheckIn.id,
            nightsStaying,
            id: `${selectedCheckIn.id}_${selectedCheckOut.id}`,
            timeBased: true,
            ticketDates,
        }
    }

    if (ticket.parent)
        updatedValue = {
            ...updatedValue,
            name: `${ticket.groupName} - ${updatedValue.name}`
        }

    if (ticket.hasParent)
        updatedValue = {
            ...updatedValue,
            name: `${ticket.parentKey.split(' ').map((a: any) => a.charAt(0).toUpperCase() + a.slice(1)).join(' ')} - ${updatedValue.name}`
        }

    updatedValue = {
        ...updatedValue,
        cost: (Number(updatedValue.val) * Number(cost)),
    }

    if (ticket.donation) {
        updatedValue = {
            ...updatedValue,
            donation: ticket.donation
        }
    }

    let current = updatedSet.find((item: any) => item.id === updatedValue.id);

    if (current) {
        if (updatedValue.val === 0 || updatedValue.val === null) {
            updatedSet = updatedSet.map((item: any) => item.id === updatedValue.id ? null : item).filter((a: any) => a);
            openNotification(updatedValue.id, `${updatedValue.name} Removed from cart.`, api)
        } else {
            updatedSet = updatedSet.map((item: any) => item.id === updatedValue.id ? updatedValue : item);
            openNotification(updatedValue.id, `${updatedValue.name} ${ticketValue} ${ticketValue > 1 ? 'tickets' : 'ticket'} in cart.`, api)
        }
    } else {
        updatedSet.push(updatedValue);
        openNotification(updatedValue.id, `${updatedValue.name} ${updatedValue.val} ${ticketValue > 1 ? 'tickets' : 'ticket'} added to cart.`, api)
    }

    setQty(updatedSet)
}

const getMultiple = (ticket: any, multiTickets: any) => {
    let multiSet: any = {};

    ticket.multiples.forEach((multiple: any) => {
        const key = multiple.key;
        const label = multiple.label;
        let parent = multiple.parent;

        if (parent) {
            parent = ticket.multiples.find((first: any) => first.key === parent).label;
        }

        const updatedItem = {
            key,
            parent: multiple.parent,
            name: multiple.name,
            limit: multiple.limit,
            id: multiple.id,
            cost: multiple.cost,
            ewizFee: multiple.ewizFee,
            limitId: multiple.limitId,
            remaining: multiple.remaining,
        }

        if (label in multiSet)
            multiSet = {
                ...multiSet,
                [label]: {
                    ...multiSet[label],
                    parent,
                    nameSet: [
                        ...multiSet[label].nameSet,
                        updatedItem
                    ],
                }
            }
        else
            multiSet = {
                ...multiSet,
                [label]: {
                    nameSet: [updatedItem],
                }
            }
    })

    return {
        ...multiTickets,
        [ticket.type]: multiSet
    }
};

const findSelectedProduct = (product: any, selectedProducts: any, event: any) => {
    const currentSelected = Object.values(selectedProducts[product?.id] || {});
    const foundProduct = event.products.find((p: any) => p.id === product.id);

    return foundProduct.variants.find((variant: any) => {
        const dumpedSet = variant.selectedOptions.map((option: any) => option.value);
        return dumpedSet.sort().join(',') === currentSelected.sort().join(',');
    });
}
