import {errorMessageHandler} from "../../utils";
import {ReportActionTypes} from "./constants";

const INIT_STATE = {
    loading: false,
    events: [],
    error: null,
    reports: [],
    eventsLoad: false,
};

type State = {
    loading?: boolean;
    events?: any;
    error?: any;
    reports?: any;
    eventsLoaded?: any;
}

export type ReportsActionType = {
    type: ReportActionTypes.API_RESPONSE_ERROR
        | ReportActionTypes.API_RESPONSE_SUCCESS
        | ReportActionTypes.GET_EVENT_LIST
        | ReportActionTypes.GET_REPORT_LIST
        | ReportActionTypes.BUILD_REPORT
        | ReportActionTypes.DOWNLOAD_CSV
        | ReportActionTypes.CLEAR_FILE_URL
        | ReportActionTypes.RESET_DOWNLOAD
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    } | any
}
export default function Reports(state: State = INIT_STATE, action: ReportsActionType) {
    switch (action.type) {
        case ReportActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ReportActionTypes.GET_EVENT_LIST: {
                    return {
                        ...state,
                        events: action.payload.data.events,
                        loading: false,
                        eventsLoaded: true,
                    }
                }
                case ReportActionTypes.BUILD_REPORT: {
                    return {
                        ...state,
                        reports: action.payload.data.reports,
                        noData: action.payload.data.noData || false,
                        file: action.payload.data.url,
                        loading: false,
                    }
                }
                case ReportActionTypes.GET_REPORT_LIST: {
                    return {
                        ...state,
                        reports: action.payload.data.reports,
                        loading: false,
                    }
                }
                case ReportActionTypes.DOWNLOAD_CSV: {
                    return {
                        ...state,
                        file: action.payload.data.url,
                        loadingCsv: false,
                    }
                }
                default:
                    return {...state, loading: false}
            }
        case ReportActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ReportActionTypes.DOWNLOAD_CSV:
                    return {
                        ...state,
                        loadingCsv: false,
                        error: errorMessageHandler(action.payload.error)
                    }
                default:
                    return {...state, loading: false, error: errorMessageHandler(action.payload.error)}
            }
        case ReportActionTypes.GET_EVENT_LIST:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case ReportActionTypes.BUILD_REPORT:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case ReportActionTypes.GET_REPORT_LIST:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case ReportActionTypes.DOWNLOAD_CSV:
            return {
                ...state,
                loadingCsv: true,
                error: null,
            }
        case ReportActionTypes.CLEAR_FILE_URL: {
            return {
                ...state,
                file: null,
            }
        }
        case ReportActionTypes.RESET_DOWNLOAD: {
            return {
                ...state,
                noData: false,
            }
        }
        default:
            return {...state};
    }
}
