import {AccountActionTypes} from "./constants";
import {APICore} from "../../helpers/api/apiCore";
import {errorMessageHandler} from "../../utils";

const api = new APICore();

const INIT_STATE = {
    loading: false,
    company: api.getImpersonation()?.company || (api.getLoggedInUser()?.company || {}),
    shopify: {
        loading: false,
        error: null,
        products: []
    }
};

type State = {
    company: any;
    loading?: boolean;
    shopify?: any;
}

export type AccountActionType = {
    type: AccountActionTypes.API_RESPONSE_ERROR
        | AccountActionTypes.CREATE_COMPANY
        | AccountActionTypes.CHECK_SHOPIFY
        | AccountActionTypes.UPDATE_COMPANY
        | AccountActionTypes.RESET
        | AccountActionTypes.CONNECT_SHOPIFY
        | AccountActionTypes.GET_SHOPIFY_PRODUCTS
        | AccountActionTypes.SET_COMPANY
        | AccountActionTypes.RESET_REDIRECT
        | AccountActionTypes.GET_COMPANY
        | AccountActionTypes.GET_ACCOUNT_LINK
        | AccountActionTypes.GET_COMPANY_USERS
        | AccountActionTypes.ADD_COMPANY_USER
        | AccountActionTypes.UPDATE_COMPANY_USER
        | AccountActionTypes.REMOVE_COMPANY_USER
        | AccountActionTypes.CLEAR_COMPANY_USER_FIELDS
        | AccountActionTypes.API_RESPONSE_SUCCESS;
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    } | any
}

export default function Account(state: State = INIT_STATE, action: AccountActionType) {
    switch (action.type) {
        case AccountActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AccountActionTypes.CREATE_COMPANY:
                    return {
                        ...state,
                        company: action.payload.data.company,
                        error: null
                    }
                case AccountActionTypes.UPDATE_COMPANY:
                    return {
                        ...state,
                        company: action.payload.data.company,
                        redirect: action.payload.data.redirect,
                        error: null,
                    }
                case AccountActionTypes.CHECK_SHOPIFY:
                    return {
                        ...state,
                        shopify: {
                            ...state.shopify,
                            loading: false,
                            account: action.payload.data.account
                        }
                    }
                case AccountActionTypes.CONNECT_SHOPIFY:
                    return {
                        ...state,
                        shopify: {
                            ...state.shopify,
                            loading: false,
                            redirect: action.payload.data.redirect,
                            updated: action.payload.data.updated || false
                        }
                    }
                case AccountActionTypes.GET_SHOPIFY_PRODUCTS:
                    return {
                        ...state,
                        shopify: {
                            ...state.shopify,
                            loading: false,
                            products: action.payload.data.products
                        }
                    }
                case AccountActionTypes.GET_COMPANY_USERS:
                    return {
                        ...state,
                        loading: false,
                        companyUsers: action.payload.data.users,
                    }
                case AccountActionTypes.ADD_COMPANY_USER:
                    return {
                        ...state,
                        loadingAddCompanyUser: false,
                        companyUsers: action.payload.data.users,
                        addUserResult: action.payload.data.result,
                        error: null,
                    }
                case AccountActionTypes.UPDATE_COMPANY_USER:
                    return {
                        ...state,
                        updatingCompanyUser: false,
                        companyUsers: action.payload.data.users,
                        updateUserResult: action.payload.data.result,
                        error: null,
                    }
                case AccountActionTypes.REMOVE_COMPANY_USER:
                    return {
                        ...state,
                        updatingCompanyUser: false,
                        companyUsers: action.payload.data.users,
                        updateUserResult: action.payload.data.result,
                        error: null,
                    }
                default:
                    return {...state, loading: false}
            }
        case AccountActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AccountActionTypes.CREATE_COMPANY:
                    return {
                        ...state,
                        loading: false,
                        error: Object.values(action.payload.error as {})[0]
                    }
                case AccountActionTypes.UPDATE_COMPANY:
                    return {
                        ...state,
                        loading: false,
                        error: Object.values(action.payload.error as {})[0]
                    }
                case AccountActionTypes.CHECK_SHOPIFY:
                    return {
                        ...state,
                        shopify: {
                            ...state.shopify,
                            error: typeof action.payload.error === 'string' ? action.payload.error : Object.values(action.payload.error as {})[0],
                            loading: false,
                        }
                    }
                case AccountActionTypes.GET_SHOPIFY_PRODUCTS:
                    return {
                        ...state,
                        shopify: {
                            ...state.shopify,
                            error: typeof action.payload.error === 'string' ? action.payload.error : Object.values(action.payload.error as {})[0],
                            loading: false,
                        }
                    }
                case AccountActionTypes.CONNECT_SHOPIFY:
                    return {
                        ...state,
                        shopify: {
                            ...state.shopify,
                            error: Object.values(action.payload.error as {})[0],
                            loading: false,
                        }
                    }
                default:
                    return {
                        ...state,
                        loading: false,
                        error: errorMessageHandler(action.payload.error),
                        loadingAddCompanyUser: false,
                        updatingCompanyUser: false,
                    }
            }
        case AccountActionTypes.RESET:
            return {
                company: {},
                loading: false,
            }
        case AccountActionTypes.RESET_REDIRECT:
            return {
                ...state,
                redirect: null
            }
        case AccountActionTypes.GET_COMPANY:
            return {
                ...state,
                loading: false,
            }
        case AccountActionTypes.CREATE_COMPANY:
            return {
                ...state,
                loading: true,
            }
        case AccountActionTypes.UPDATE_COMPANY:
            return {
                ...state,
                loading: true,
            }
        case AccountActionTypes.SET_COMPANY:
            return {
                ...state,
                company: action.payload
            }
        case AccountActionTypes.CHECK_SHOPIFY:
            return {
                ...state,
                shopify: {
                    ...state.shopify,
                    loading: true
                }
            }
        case AccountActionTypes.CONNECT_SHOPIFY:
            return {
                ...state,
                shopify: {
                    ...state.shopify,
                    loading: true
                }
            }
        case AccountActionTypes.GET_COMPANY_USERS:
            return {
                ...state,
                loading: true,
                loadingAddCompanyUser: false,
                updatingCompanyUser: false,
                updateUserResult: null,
                addUserResult: null,
            }
        case AccountActionTypes.ADD_COMPANY_USER:
            return {
                ...state,
                loadingAddCompanyUser: true,
                addUserResult: null,
            }
        case AccountActionTypes.UPDATE_COMPANY_USER:
            return {
                ...state,
                updatingCompanyUser: true,
                updateUserResult: null,
            }
        case AccountActionTypes.REMOVE_COMPANY_USER:
            return {
                ...state,
                updatingCompanyUser: true,
                updateUserResult: null,
            }
        case AccountActionTypes.CLEAR_COMPANY_USER_FIELDS:
            return {
                ...state,
                loadingAddCompanyUser: false,
                addUserResult: null,
                error: null,
                updatingCompanyUser: false,
                updateUserResult: null
            }
        default:
            return {...state};
    }
}
