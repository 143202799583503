import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {SagaIterator} from "@redux-saga/core";
import {adminApiResponseError, adminApiResponseSuccess, resetImpersonation} from "./actions";
import {
    deleteStripeAccountsApi,
    getProfile,
    getRsvpsApi,
    getStripeAccountsApi,
    getUsersApi,
    updateRsvpApi,
    updateUserApi,
    getRentalsApi, updateRentalsEventApi, adminActionApi,
} from "../../helpers";
import {AdminActionTypes} from "./constants";
import {APICore} from "../../helpers/api/apiCore";

const api = new APICore();

function* getUsersEvent(): SagaIterator {
    try {
        const response = yield call(getUsersApi);
        yield put(adminApiResponseSuccess(AdminActionTypes.GET_USERS, response.data));
    } catch (error: any) {
        yield put(adminApiResponseError(AdminActionTypes.GET_USERS, error));
    }
}

function* getStripeAccountsEvent(): SagaIterator {
    try {
        const response = yield call(getStripeAccountsApi);
        yield put(adminApiResponseSuccess(AdminActionTypes.GET_STRIPE_ACCOUNTS, response.data));
    } catch (error: any) {
        yield put(adminApiResponseError(AdminActionTypes.GET_STRIPE_ACCOUNTS, error));
    }
}

function* deleteStripeAccountEvent(data: { payload: string, type: AdminActionTypes }): SagaIterator {
    try {
        const response = yield call(deleteStripeAccountsApi, data.payload);
        yield put(adminApiResponseSuccess(AdminActionTypes.DELETE_STRIPE_ACCOUNT, response.data));
    } catch (error: any) {
        yield put(adminApiResponseError(AdminActionTypes.DELETE_STRIPE_ACCOUNT, error));
    }
}

function* getRsvpsEvent(data: {payload: any, type: AdminActionTypes}): SagaIterator {
    try {
        const response = yield call(getRsvpsApi, data.payload);
        yield put(adminApiResponseSuccess(AdminActionTypes.GET_RSVPS, response.data));
    } catch (error: any) {
        yield put(adminApiResponseError(AdminActionTypes.GET_RSVPS, error));
    }
}

function* getRentalsEvent(): SagaIterator {
    try {
        const response = yield call(getRentalsApi);
        yield put(adminApiResponseSuccess(AdminActionTypes.GET_RENTALS, response.data));
    } catch (error: any) {
        yield put(adminApiResponseError(AdminActionTypes.GET_RENTALS, error));
    }
}

function* updateRentalsEvent(data: {payload: any, type: AdminActionTypes}): SagaIterator {
    try {
        const response = yield call(updateRentalsEventApi, data.payload);
        yield put(adminApiResponseSuccess(AdminActionTypes.UPDATE_RENTALS, response.data));
    } catch (error: any) {
        yield put(adminApiResponseError(AdminActionTypes.UPDATE_RENTALS, error));
    }
}

function* refundEvent(data: {payload: any, type: AdminActionTypes}): SagaIterator {
    try {
        const response = yield call(adminActionApi, data.payload, 'refund');
        yield put(adminApiResponseSuccess(AdminActionTypes.REFUND, response.data));
    } catch (error: any) {
        yield put(adminApiResponseError(AdminActionTypes.REFUND, error));
    }
}

function* getEmailsEvent(data: {payload: any, type: AdminActionTypes}): SagaIterator {
    try {
        const response = yield call(adminActionApi, data.payload, 'getEmailData');
        yield put(adminApiResponseSuccess(AdminActionTypes.GET_EMAILS, response.data));
    } catch (error: any) {
        yield put(adminApiResponseError(AdminActionTypes.GET_EMAILS, error));
    }
}

function* updateEmailEvent(data: {payload: any, type: AdminActionTypes}): SagaIterator {
    try {
        const response = yield call(adminActionApi, data.payload, 'updateEmailData');
        yield put(adminApiResponseSuccess(AdminActionTypes.GET_EMAILS, response.data));
    } catch (error: any) {
        yield put(adminApiResponseError(AdminActionTypes.GET_EMAILS, error));
    }
}

function* manageReservationLinkEvent(data: {payload: any, type: AdminActionTypes}): SagaIterator {
    try {
        const response = yield call(adminActionApi, data.payload, 'manageReservationLink');
        yield put(adminApiResponseSuccess(AdminActionTypes.MANAGE_RESERVATION_LINK, response.data));
    } catch (error: any) {
        yield put(adminApiResponseError(AdminActionTypes.MANAGE_RESERVATION_LINK, error));
    }
}

function* changeQtyEvent(data: {payload: any, type: AdminActionTypes}): SagaIterator {
    try {
        const response = yield call(adminActionApi, data.payload, 'changeQty');
        yield put(adminApiResponseSuccess(AdminActionTypes.UPDATE_CART_CHANGE, response.data));
    } catch (error: any) {
        yield put(adminApiResponseError(AdminActionTypes.UPDATE_CART_CHANGE, error));
    }
}

function* changeTicketEvent(data: {payload: any, type: AdminActionTypes}): SagaIterator {
    try {
        const response = yield call(adminActionApi, data.payload, 'changeTicket');
        yield put(adminApiResponseSuccess(AdminActionTypes.CHANGE_TICKET, response.data));
    } catch (error: any) {
        yield put(adminApiResponseError(AdminActionTypes.CHANGE_TICKET, error));
    }
}

function* retryTicketEvent(data: {payload: any, type: AdminActionTypes}): SagaIterator {
    try {
        const response = yield call(adminActionApi, data.payload, 'retry');
        yield put(adminApiResponseSuccess(AdminActionTypes.RETRY_TICKET, response.data));
    } catch (error: any) {
        yield put(adminApiResponseError(AdminActionTypes.RETRY_TICKET, error));
    }
}

function* cancelTicketEvent(data: {payload: any, type: AdminActionTypes}): SagaIterator {
    try {
        const response = yield call(adminActionApi, data.payload, 'cancel');
        yield put(adminApiResponseSuccess(AdminActionTypes.CANCEL_TICKET, response.data));
    } catch (error: any) {
        yield put(adminApiResponseError(AdminActionTypes.CANCEL_TICKET, error));
    }
}

function* updateRsvpEvent(data: {payload: any, type: AdminActionTypes}): SagaIterator {
    try {
        const response = yield call(updateRsvpApi, data.payload);
        yield put(adminApiResponseSuccess(AdminActionTypes.UPDATE_RSVP, response.data));
    } catch (error: any) {
        yield put(adminApiResponseError(AdminActionTypes.UPDATE_RSVP, error));
    }
}

function* updateUserEvent(data: {payload: any, type: AdminActionTypes}): SagaIterator {
    try {
        const response = yield call(updateUserApi, data.payload);
        yield put(adminApiResponseSuccess(AdminActionTypes.UPDATE_USER, response.data));
    } catch (error: any) {
        yield put(adminApiResponseError(AdminActionTypes.UPDATE_USER, error));
    }
}

function* resetImpersonationEvent(): SagaIterator {
    try {
        api.setImpersonationHeaders(null);
        api.setImpersonation(null);
        yield put(adminApiResponseSuccess(AdminActionTypes.RESET_IMPERSONATION, {}))
    } catch (e: any) {
        yield put(adminApiResponseError(AdminActionTypes.RESET_IMPERSONATION, e))
    }
}

function* impersonateUsersEvent(payload: { payload: any, type: AdminActionTypes }): SagaIterator {
    try {
        api.setImpersonationHeaders(payload.payload);
        const response = yield call(getProfile)
        api.setImpersonation(response.data.user);
        yield put(adminApiResponseSuccess(AdminActionTypes.IMPERSONATE, payload.payload))
    } catch (error: any) {
        yield put(resetImpersonation())
    }
}

export function* watchImpersonateEvent() {
    yield takeEvery(AdminActionTypes.IMPERSONATE, impersonateUsersEvent)
}

export function* watchGetUsersEvent() {
    yield takeEvery(AdminActionTypes.GET_USERS, getUsersEvent)
}

export function* watchResetImpersonationEvent() {
    yield takeEvery(AdminActionTypes.RESET_IMPERSONATION, resetImpersonationEvent)
}

export function* watchGetStripeAccountsEvent() {
    yield takeEvery(AdminActionTypes.GET_STRIPE_ACCOUNTS, getStripeAccountsEvent)
}

export function* watchDeleteStripeAccountEvent() {
    yield takeEvery(AdminActionTypes.DELETE_STRIPE_ACCOUNT, deleteStripeAccountEvent)
}

export function* watchGetRsvpsEvent() {
    yield takeEvery(AdminActionTypes.GET_RSVPS, getRsvpsEvent)
}

export function* watchRetryTicketEvent() {
    yield takeEvery(AdminActionTypes.RETRY_TICKET, retryTicketEvent)
}

export function* watchCancelTicketEvent() {
    yield takeEvery(AdminActionTypes.CANCEL_TICKET, cancelTicketEvent)
}

export function* watchUpdateRsvpEvent() {
    yield takeEvery(AdminActionTypes.UPDATE_RSVP, updateRsvpEvent)
}

export function* watchUpdateUserEvent() {
    yield takeEvery(AdminActionTypes.UPDATE_USER, updateUserEvent)
}

export function* watchGetRentalsEvent() {
    yield takeEvery(AdminActionTypes.GET_RENTALS, getRentalsEvent)
}

export function* watchUpdateRentalsEvent() {
    yield takeEvery(AdminActionTypes.UPDATE_RENTALS, updateRentalsEvent)
}

export function* watchRefundEvent() {
    yield takeEvery(AdminActionTypes.REFUND, refundEvent)
}

export function* watchGetEmailsEvent() {
    yield takeEvery(AdminActionTypes.GET_EMAILS, getEmailsEvent)
}

export function* watchUpdateEmailEvent() {
    yield takeEvery(AdminActionTypes.UPDATE_EMAIL, updateEmailEvent)
}

export function* watchManageReservationEvent() {
    yield takeEvery(AdminActionTypes.MANAGE_RESERVATION_LINK, manageReservationLinkEvent)
}

export function* watchChangeQtyEvent() {
    yield takeEvery(AdminActionTypes.UPDATE_CART_CHANGE, changeQtyEvent)
}

export function* watchChangeTicketEvent() {
    yield takeEvery(AdminActionTypes.CHANGE_TICKET, changeTicketEvent)
}

function* adminSaga() {
    yield all([
        fork(watchGetUsersEvent),
        fork(watchCancelTicketEvent),
        fork(watchRefundEvent),
        fork(watchImpersonateEvent),
        fork(watchResetImpersonationEvent),
        fork(watchGetStripeAccountsEvent),
        fork(watchGetRsvpsEvent),
        fork(watchDeleteStripeAccountEvent),
        fork(watchUpdateRsvpEvent),
        fork(watchUpdateRentalsEvent),
        fork(watchGetRentalsEvent),
        fork(watchRetryTicketEvent),
        fork(watchUpdateUserEvent),
        fork(watchGetEmailsEvent),
        fork(watchUpdateEmailEvent),
        fork(watchManageReservationEvent),
        fork(watchChangeQtyEvent),
        fork(watchChangeTicketEvent),
    ]);
}

export default adminSaga;
