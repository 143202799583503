import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {ReportActionTypes} from "./constants";
import {SagaIterator} from "@redux-saga/core";
import {doReportAction} from "../../helpers";
import {reportApiResponseError, reportApiResponseSuccess} from "./actions";

function* getEventListEvent(payload: { payload: any, type: ReportActionTypes }): SagaIterator {
    try {
        const response = yield call(doReportAction, payload.payload);
        yield put(reportApiResponseSuccess(ReportActionTypes.GET_EVENT_LIST, response.data));
    } catch (error: any) {
        yield put(reportApiResponseError(ReportActionTypes.GET_EVENT_LIST, error));
    }
}

function* getReportListEvent(payload: { payload: any, type: ReportActionTypes }): SagaIterator {
    try {
        const response = yield call(doReportAction, payload.payload);
        yield put(reportApiResponseSuccess(ReportActionTypes.GET_REPORT_LIST, response.data));
    } catch (error: any) {
        yield put(reportApiResponseError(ReportActionTypes.GET_REPORT_LIST, error));
    }
}

function* buildReportEvent(payload: { payload: any, type: ReportActionTypes }): SagaIterator {
    try {
        const response = yield call(doReportAction, payload.payload);
        yield put(reportApiResponseSuccess(ReportActionTypes.BUILD_REPORT, response.data));
    } catch (error: any) {
        yield put(reportApiResponseError(ReportActionTypes.BUILD_REPORT, error));
    }
}

function* downloadCsvEvent(payload: { payload: any, type: ReportActionTypes }): SagaIterator {
    try {
        const response = yield call(doReportAction, payload.payload);
        yield put(reportApiResponseSuccess(ReportActionTypes.DOWNLOAD_CSV, response.data));
    } catch (error: any) {
        yield put(reportApiResponseError(ReportActionTypes.DOWNLOAD_CSV, error));
    }
}

export function* watchGetEventListEvent() {
    yield takeEvery(ReportActionTypes.GET_EVENT_LIST, getEventListEvent)
}

export function* watchGetGetReportListEvent() {
    yield takeEvery(ReportActionTypes.GET_REPORT_LIST, getReportListEvent)
}

export function* watchBuildReportEvent() {
    yield takeEvery(ReportActionTypes.BUILD_REPORT, buildReportEvent)
}

export function* watchDownloadCsvEvent() {
    yield takeEvery(ReportActionTypes.DOWNLOAD_CSV, downloadCsvEvent)
}

function* reportsSaga() {
    yield all([
        fork(watchGetEventListEvent),
        fork(watchBuildReportEvent),
        fork(watchGetGetReportListEvent),
        fork(watchDownloadCsvEvent),
    ]);
}

export default reportsSaga;
