import { APICore } from './apiCore';

const api = new APICore();

function login(params: { email: string; password: string }) {
    const baseUrl = '/user/login/';
    return api.create(`${baseUrl}`, params);
}

function logout() {
    const baseUrl = '/logout/';
    return api.create(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
    const baseUrl = '/user/signup/';
    return api.create(`${baseUrl}`, params);
}

function confirm(params: { code: string, email: string, password: string }) {
    const baseUrl = '/user/confirm/';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
    const baseUrl = '/forget-password/';
    return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: { email: string }) {
    const baseUrl = '/password/reset/confirm/';
    return api.create(`${baseUrl}`, params);
}

function refreshTokenApi(params: { refreshToken: string }) {
    const baseUrl = '/user/refresh';
    return api.create(`${baseUrl}`, params);
}

function authApi(params: { code: any }) {
    const baseUrl = '/user/login/';
    return api.create(`${baseUrl}`, params);
}

export {login, logout, signup, forgotPassword, forgotPasswordConfirm, confirm, refreshTokenApi, authApi};
