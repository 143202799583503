import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {SagaIterator} from "@redux-saga/core";
import {eventApiResponseError, eventApiResponseSuccess, resetSearch} from "./actions";
import {EventActionTypes} from "./constats";
import {
    checkoutApi,
    createEventApi,
    createRsvpApi,
    editEventApi,
    getCartApi,
    getEventApi,
    getEventsApi,
    getPublicEventApi,
    eventCheckinApi,
    checkDiscountApi,
    updateCartApi,
    getRentalInfoApi,
    rentalCheckoutApi,
    pickupInfoEventApi,
    getRentalCheckinApi, checkoutRentalCheckinApi, rsvpSearchApi, addToWaitlistApi, adminCartApi,
} from "../../helpers";

function* getEvents(payload: { payload: {}, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(getEventsApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.GET_EVENTS, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.GET_EVENTS, error));
    }
}

function* createEvent(payload: { payload: {}, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(createEventApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.EVENT_CREATED, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.CREATE_EVENT, error));
    }
}

function* duplicateEventEvent(payload: { payload: {}, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(createEventApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.EVENT_CREATED, response.data));
        window.location.href = `/events/${response.data.eventId}/details`;
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.DUPLICATE, error));
    }
}

function* getEvent(payload: { payload: { id: string }, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(getEventApi, payload.payload.id);
        yield  put(eventApiResponseSuccess(EventActionTypes.GET_EVENT, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.GET_EVENT, error));
    }
}

function* getRentalsEvent(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(getRentalInfoApi, payload.payload);
        yield  put(eventApiResponseSuccess(EventActionTypes.GET_RENTALS, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.GET_RENTALS, error));
    }
}

function* getPublicEvent(payload: { payload: { id: string }, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(getPublicEventApi, payload.payload.id);
        yield put(eventApiResponseSuccess(EventActionTypes.GET_PUBLIC_EVENT, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.GET_PUBLIC_EVENT, error));
    }
}

function* createRsvpEvent(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(createRsvpApi, payload.payload);
        yield  put(eventApiResponseSuccess(EventActionTypes.CREATE_RSVP, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.CREATE_RSVP, error));
    }
}

function* checkout(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(checkoutApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.CHECKOUT, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.CHECKOUT, error));
    }
}

function* rentalCheckoutEvent(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(rentalCheckoutApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.RENTAL_CHECKOUT, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.RENTAL_CHECKOUT, error));
    }
}

function* getPickupInfoEvent(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(pickupInfoEventApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.GET_PICKUP_INFO, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.GET_PICKUP_INFO, error));
    }
}

function* getRentalEvent(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(getRentalCheckinApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.GET_RENTAL, response));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.GET_RENTAL, error));
    }
}

function* checkoutRentalEvent(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(checkoutRentalCheckinApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.CHECKOUT_RENTAL, response));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.CHECKOUT_RENTAL, error));
    }
}

function* getCart(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(getCartApi, payload.payload.id, payload.payload.cartId);
        if (response.data.message) {
            sessionStorage.removeItem('cartSession');
        }
        yield put(eventApiResponseSuccess(EventActionTypes.GET_CART, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.GET_CART, error));
    }
}

function* editEvent(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        yield call(editEventApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.EDIT_EVENT, payload.payload));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.EDIT_EVENT, error));
    }
}

function* checkinEvent(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(eventCheckinApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.EVENT_CHECKIN, response));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.EVENT_CHECKIN, error));
    }
}

function* updateCartEvent(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(updateCartApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.UPDATE_CART, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.UPDATE_CART, error));
    }
}

function* updateAdminCartEvent(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(adminCartApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.UPDATE_ADMIN_CART, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.UPDATE_ADMIN_CART, error));
    }
}

function* sendPaymentAdminEvent(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(adminCartApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.SEND_PAYMENT_ADMIN, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.SEND_PAYMENT_ADMIN, error));
    }
}

function* checkoutTicketsAdminEvent(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(adminCartApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.CHECKOUT_ADMIN_CART, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.CHECKOUT_ADMIN_CART, error));
    }
}

function* checkDiscountEvent(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(checkDiscountApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.CHECK_DISCOUNT, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.CHECK_DISCOUNT, error));
    }
}

function* removeDiscountEvent(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(checkDiscountApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.CHECK_DISCOUNT, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.CHECK_DISCOUNT, error));
    }
}

function* rsvpSearchEvent(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(rsvpSearchApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.RSVP_SEARCH, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.RSVP_SEARCH, error));
    }
}

function* getCustomerDataEvent(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(rsvpSearchApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.FETCH_CUSTOMER_DATA, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.FETCH_CUSTOMER_DATA, error));
    }
}

function* getTimeBasedRsvpEvent(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(rsvpSearchApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.GET_TIMEBASED_RSVP, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.GET_TIMEBASED_RSVP, error));
    }
}

function* addToWaitlistEvent(payload: { payload: any, type: EventActionTypes }): SagaIterator {
    try {
        const response = yield call(addToWaitlistApi, payload.payload);
        yield put(eventApiResponseSuccess(EventActionTypes.ADD_TO_WAITLIST, response.data));
    } catch (error: any) {
        yield put(eventApiResponseError(EventActionTypes.ADD_TO_WAITLIST, error));
    }
}

function* resetSearchEvent(): SagaIterator {
    yield put(resetSearch());
}

export function* watchGetSessionEvents() {
    yield takeEvery(EventActionTypes.GET_CART, getCart)
}

export function* watchCheckoutEvents() {
    yield takeEvery(EventActionTypes.CHECKOUT, checkout)
}

export function* watchEventGetEvents() {
    yield takeEvery(EventActionTypes.GET_EVENTS, getEvents);
}

export function* watchEventCreate() {
    yield takeEvery(EventActionTypes.CREATE_EVENT, createEvent);
}

export function* watchEventGetEvent() {
    yield takeEvery(EventActionTypes.GET_EVENT, getEvent);
}

export function* watchEventGetPublicEvent() {
    yield takeEvery(EventActionTypes.GET_PUBLIC_EVENT, getPublicEvent);
}

export function* watchCreateRsvpEvent() {
    yield takeEvery(EventActionTypes.CREATE_RSVP, createRsvpEvent)
}

export function* watchEditEventEvent() {
    yield takeEvery(EventActionTypes.EDIT_EVENT, editEvent)
}

export function* watchEventCheckinEvent() {
    yield takeEvery(EventActionTypes.EVENT_CHECKIN, checkinEvent)
}

export function* watchUpdateCartEvent() {
    yield takeEvery(EventActionTypes.UPDATE_CART, updateCartEvent)
}

export function* watchUpdateAdminCartEvent() {
    yield takeEvery(EventActionTypes.UPDATE_ADMIN_CART, updateAdminCartEvent)
}

export function* watchSendPaymentAdminEvent() {
    yield takeEvery(EventActionTypes.SEND_PAYMENT_ADMIN, sendPaymentAdminEvent)
}

export function* watchCheckoutTicketsAdminEvent() {
    yield takeEvery(EventActionTypes.CHECKOUT_ADMIN_CART, checkoutTicketsAdminEvent)
}

export function* watchCheckDiscountEvent() {
    yield takeEvery(EventActionTypes.CHECK_DISCOUNT, checkDiscountEvent)
}

export function* watchRemoveDiscountEvent() {
    yield takeEvery(EventActionTypes.REMOVE_DISCOUNT, removeDiscountEvent)
}

export function* watchDuplicateEvent() {
    yield takeEvery(EventActionTypes.DUPLICATE, duplicateEventEvent)
}

export function* watchGetRentalsEvent() {
    yield takeEvery(EventActionTypes.GET_RENTALS, getRentalsEvent)
}

export function* watchRentalCheckoutEvent() {
    yield takeEvery(EventActionTypes.RENTAL_CHECKOUT, rentalCheckoutEvent)
}

export function* watchGetPickupInfoEvent() {
    yield takeEvery(EventActionTypes.GET_PICKUP_INFO, getPickupInfoEvent)
}

export function* watchGetRentalEvent() {
    yield takeEvery(EventActionTypes.GET_RENTAL, getRentalEvent)
}

export function* watchCheckoutRentalEvent() {
    yield takeEvery(EventActionTypes.CHECKOUT_RENTAL, checkoutRentalEvent)
}

export function* watchRsvpSearchEvent() {
    yield takeEvery(EventActionTypes.RSVP_SEARCH, rsvpSearchEvent)
}

export function* watchSearchResetEvent() {
    yield takeEvery(EventActionTypes.RESET_RSVP, resetSearchEvent)
}

export function* watchGetCustomerDataEvent() {
    yield takeEvery(EventActionTypes.FETCH_CUSTOMER_DATA, getCustomerDataEvent)
}

export function* watchGetTimeBasedRsvpEvent() {
    yield takeEvery(EventActionTypes.GET_TIMEBASED_RSVP, getTimeBasedRsvpEvent)
}

export function* watchAddToWaitListEvent() {
    yield takeEvery(EventActionTypes.ADD_TO_WAITLIST, addToWaitlistEvent)
}

function* eventsSaga() {
    yield all([
        fork(watchEventGetEvents),
        fork(watchAddToWaitListEvent),
        fork(watchUpdateAdminCartEvent),
        fork(watchSendPaymentAdminEvent),
        fork(watchCheckoutTicketsAdminEvent),
        fork(watchSearchResetEvent),
        fork(watchGetTimeBasedRsvpEvent),
        fork(watchEventCreate),
        fork(watchRsvpSearchEvent),
        fork(watchGetCustomerDataEvent),
        fork(watchEventGetEvent),
        fork(watchEventGetPublicEvent),
        fork(watchCreateRsvpEvent),
        fork(watchGetSessionEvents),
        fork(watchEditEventEvent),
        fork(watchUpdateCartEvent),
        fork(watchEventCheckinEvent),
        fork(watchCheckDiscountEvent),
        fork(watchCheckoutEvents),
        fork(watchRemoveDiscountEvent),
        fork(watchDuplicateEvent),
        fork(watchGetRentalsEvent),
        fork(watchRentalCheckoutEvent),
        fork(watchGetPickupInfoEvent),
        fork(watchGetRentalEvent),
        fork(watchCheckoutRentalEvent),
    ]);
}

export default eventsSaga;
