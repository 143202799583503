import {DashboardActionTypes} from "./constats";
import {DashboardActionType} from "./reducers";

export const dashboardApiResponseSuccess = (actionType: string, data: {}): DashboardActionType => ({
    type: DashboardActionTypes.API_RESPONSE_SUCCESS,
    payload: {actionType, data},
});

export const dashboardApiResponseError = (actionType: string, error: string): DashboardActionType => ({
    type: DashboardActionTypes.API_RESPONSE_ERROR,
    payload: {actionType, error},
});

export const getAll = (data: {id?: any, companyId?: any, filters?: any}): DashboardActionType => ({
    type: DashboardActionTypes.GET_ALL,
    payload: {id: data.id, companyId: data.companyId, filters: data.filters}
})

export const getStatistics = (data: {id?: any, filters: any}): DashboardActionType => ({
    type: DashboardActionTypes.GET_STATS,
    payload: {id: data.id, filters: data.filters}
})

export const getRsvpStats = (data: {id?: any, filters: any}): DashboardActionType => ({
    type: DashboardActionTypes.GET_RSVP_STATS,
    payload: {id: data.id, filters: data.filters}
})
