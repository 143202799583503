export type MenuItemType = {
    key: string;
    label: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    children?: MenuItemType[];
    preProduction?: boolean;
    roleRestriction?: any;
    isEnterprise?: any;
};

const MENU_ITEMS: MenuItemType[] = [
    { key: 'navigation', label: 'Navigation', isTitle: true },
    {
        key: 'dashboard',
        label: 'Dashboard',
        isTitle: false,
        icon: 'uil-chart',
        url: '/dashboard/events',
        isEnterprise: true,
    },
    {
        key: 'events',
        label: 'Events',
        isTitle: false,
        icon: 'uil-briefcase',
        roleRestriction: ['Reporting'],
        children: [
            {
                key: 'event-list',
                label: 'List',
                url: '/events/list',
                parentKey: 'events',
            },
            {
                key: 'event-create-event',
                label: 'Create Event',
                url: '/events/new',
                parentKey: 'events',
            },
        ],
    },
    {
        key: 'reports',
        label: 'Reports',
        isTitle: false,
        icon: 'uil-chart-pie',
        isEnterprise: true,
        children: [
            {
                key: 'financial-reports',
                label: 'Financial',
                url: '/reports/financial',
                parentKey: 'reports',
            },
            {
                key: 'attendance-reports',
                label: 'Attendance',
                url: '/reports/attendance',
                parentKey: 'reports',
            },
            {
                key: 'customer-reports',
                label: 'Customer',
                url: '/reports/customer',
                parentKey: 'reports',
            },
        ],
    },
];

export { MENU_ITEMS };
