import {AccountActionTypes} from "./constants";
import {AccountActionType} from "./reducers";

export const accountApiResponseSuccess = (actionType: string, data: {}): AccountActionType => ({
    type: AccountActionTypes.API_RESPONSE_SUCCESS,
    payload: {actionType, data},
});
// common error
export const accountApiResponseError = (actionType: string, error: string): AccountActionType => ({
    type: AccountActionTypes.API_RESPONSE_ERROR,
    payload: {actionType, error},
});

export const createCompany = (data: {}): AccountActionType => ({
    type: AccountActionTypes.CREATE_COMPANY,
    payload: data
});
export const updateCompany = (data: {}): AccountActionType => ({
    type: AccountActionTypes.UPDATE_COMPANY,
    payload: data
})

export const reset = (): AccountActionType => ({
    type: AccountActionTypes.RESET,
    payload: null
})

export const getCompany = (): AccountActionType => ({
    type: AccountActionTypes.GET_COMPANY,
    payload: null
})

export const getAccountLink = (id: string): AccountActionType => ({
    type: AccountActionTypes.GET_ACCOUNT_LINK,
    payload: id
})

export const resetRedirect = (): AccountActionType => ({
    type: AccountActionTypes.RESET_REDIRECT,
    payload: null
})

export const setCompany = (company: any): AccountActionType => ({
    type: AccountActionTypes.SET_COMPANY,
    payload: company
})

export const checkShopify = (data?: {}): AccountActionType => ({
    type: AccountActionTypes.CHECK_SHOPIFY,
    payload: data
})

export const connectShopify = (data: any): AccountActionType => ({
    type: AccountActionTypes.CONNECT_SHOPIFY,
    payload: data
})

export const getShopifyProducts = (): AccountActionType => ({
    type: AccountActionTypes.GET_SHOPIFY_PRODUCTS,
    payload: null
})

export const getCompanyUsers = (data: any): AccountActionType => ({
    type: AccountActionTypes.GET_COMPANY_USERS,
    payload: data
})

export const addCompanyUser = (data: any): AccountActionType => ({
    type: AccountActionTypes.ADD_COMPANY_USER,
    payload: data
})

export const updateCompanyUser = (data: any): AccountActionType => ({
    type: AccountActionTypes.UPDATE_COMPANY_USER,
    payload: data
})

export const removeCompanyUser = (data: any): AccountActionType => ({
    type: AccountActionTypes.REMOVE_COMPANY_USER,
    payload: data
})

export const clearCompanyUserFields = (): AccountActionType => ({
    type: AccountActionTypes.CLEAR_COMPANY_USER_FIELDS,
    payload: null
})
