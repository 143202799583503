import React, {Suspense} from 'react';
import {useRoutes} from 'react-router-dom';
import {DefaultLayout, VerticalLayout} from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// lazy load all the views

// auth
const Login = React.lazy(() => import('pages/account/Login'));
const Logout = React.lazy(() => import('pages/account/Logout'));
const Register = React.lazy(() => import('pages/account/Register'));
const Confirm = React.lazy(() => import('pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('pages/account/ForgetPassword'));
const Profile = React.lazy(() => import('pages/account/Profile'));

const EcommerceDashboard = React.lazy(() => import('pages/Dashboard'));

const EventList = React.lazy(() => import('pages/Events/List'));
const EventCreate = React.lazy(() => import('pages/Events/EventForm'));
const EventDetail = React.lazy(() => import('pages/Events/Detail'));
const EventCheckin = React.lazy(() => import('pages/Events/Public/EventCheckin'));
const RentalCheckin = React.lazy(() => import('pages/Events/RentalCheckin'));
const PublicEvent = React.lazy(() => import('pages/Events/Public/PublicEvent'));
const RentalEvent = React.lazy(() => import('pages/Events/Public/RentalEvent'));

const Impersonate = React.lazy(() => import('pages/admin/AdminPanel'));
const ManageReservation = React.lazy(() => import('pages/reservation/ManageReservation'));
const AuthReservation = React.lazy(() => import('pages/reservation/AuthReservation'));

const FinancialReports = React.lazy(() => import('pages/reports/FinancialReports'));
const AttendanceReports = React.lazy(() => import('pages/reports/AttendanceReports'));
const CustomerReports = React.lazy(() => import('pages/reports/CustomerReports'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({component: Component}: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component/>
    </Suspense>
);

const AllRoutes = () => {
    const Layout: React.ComponentType = VerticalLayout;

    return useRoutes([
        {path: '/', element: <Root/>},
        {
            path: '/',
            element: <DefaultLayout layoutColor={'#ffffff'} />,
            children: [
                {
                    path: '/events/p/:id',
                    element: <LoadComponent component={PublicEvent}/>
                },
                {
                    path: '/events/p/:id/rentals',
                    element: <LoadComponent component={RentalEvent}/>
                }
            ]
        },
        {
            path: '/',
            element: <DefaultLayout/>,
            children: [
                {
                    path: 'account',
                    children: [
                        {path: 'login', element: <LoadComponent component={Login}/>},
                        {path: 'register', element: <LoadComponent component={Register}/>},
                        {path: 'confirm', element: <LoadComponent component={Confirm}/>},
                        {path: 'forget-password', element: <LoadComponent component={ForgetPassword}/>},
                        {path: 'logout', element: <LoadComponent component={Logout}/>},
                    ],
                }
            ],
        },
        {
            path: '/',
            element: <PrivateRoute component={Layout} roles={['SuperAdmin']}/>,
            children: [
                {
                    path: 'account',
                    children: [
                        {
                            path: 'admin', element: <LoadComponent component={Impersonate}/>
                        }
                    ]
                }
            ]
        },
        {
            path: '/',
            element: <DefaultLayout layoutColor={'#ffffff'}/>,
            children: [
                {
                    path: '/',
                    children: [
                        {
                            path: '/reservations/:id', element: <LoadComponent component={ManageReservation}/>
                        },
                        {
                            path: '/reservations/:id/accept', element: <LoadComponent component={ManageReservation}/>
                        },
                        {
                            path: '/reservations/auth', element: <LoadComponent component={AuthReservation}/>
                        },
                    ]
                }
            ]
        },
        {
            path: '/',
            element: <PrivateRoute component={Layout} roles={['SuperAdmin', 'Admin']}/>,
            children: [
                {
                    path: 'events',
                    children: [
                        {
                            path: 'list',
                            element: <LoadComponent component={EventList}/>
                        },
                        {
                            path: 'new',
                            element: <LoadComponent component={EventCreate} key={'new'}/>
                        },
                        {
                            path: ':id/details',
                            element: <LoadComponent component={EventDetail}/>
                        },
                        {
                            path: ':id/edit',
                            element: <LoadComponent component={EventCreate} key={'edit'}/>
                        },
                        {
                            path: ':id/checkin/:email/:code',
                            element: <LoadComponent component={EventCheckin}/>
                        },
                        {
                            path: ':id/rentals/checkin/:rentalId',
                            element: <LoadComponent component={RentalCheckin}/>
                        }
                    ]
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute component={Layout} roles={['SuperAdmin', 'Admin', 'Reporting']}/>,
            children: [
                {
                    path: 'dashboard',
                    children: [
                        {
                            path: 'events',
                            element: <LoadComponent component={EcommerceDashboard}/>,
                        },
                    ],
                },
                {
                    path: 'account',
                    children: [
                        {
                            path: 'profile',
                            element: <LoadComponent component={Profile}/>
                        }
                    ]
                },
                {
                    path: 'reports',
                    children: [
                        {
                            path: 'financial',
                            element: <LoadComponent component={FinancialReports}/>
                        },
                        {
                            path: 'attendance',
                            element: <LoadComponent component={AttendanceReports}/>
                        },
                        {
                            path: 'customer',
                            element: <LoadComponent component={CustomerReports}/>
                        }
                    ]
                }
            ]
        },
    ]);
};

export {AllRoutes};
