export enum ReportActionTypes {
    API_RESPONSE_SUCCESS = '@@reports/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@reports/API_RESPONSE_ERROR',

    GET_EVENT_LIST = '@@reports/GET_EVENT_LIST',
    GET_REPORT_LIST = '@@reports/GET_REPORT_LIST',
    BUILD_REPORT = '@@reports/BUILD_REPORT',

    DOWNLOAD_CSV = '@@reports/DOWNLOAD_CSV',
    CLEAR_FILE_URL = '@@reports/CLEAR_FILE_URL',
    RESET_DOWNLOAD = '@@reports/RESET_DOWNLOAD',
}
