import { APICore } from './apiCore';

const api = new APICore();
export function getReservationApi(data: any) {
    const baseUrl = `/reservations/${data.id}`

    return api.get(baseUrl, {})
}

export function sendReservationCodeApi(id: any) {
    const baseUrl = `/reservations/authCode`

    return api.create(baseUrl, {id})
}

export function confirmReservationCodeApi(code: any) {
    const baseUrl = `/reservations/authCode`

    return api.create(baseUrl, {code, confirm: true})
}

export function manageReservationApi(data: any) {
    const baseUrl = `/reservations/${data.code}/manage`

    return api.create(baseUrl, {...data})
}

export function acceptTransferApi(data: any) {
    const baseUrl = `/reservations/authCode`

    return api.create(baseUrl, {code: data, accept: true})
}
