import {ReportsActionType} from "./reducers";
import {ReportActionTypes} from "./constants";

export const reportApiResponseSuccess = (actionType: string, data: {}): ReportsActionType => ({
    type: ReportActionTypes.API_RESPONSE_SUCCESS,
    payload: {actionType, data},
});

export const reportApiResponseError = (actionType: string, error: string): ReportsActionType => ({
    type: ReportActionTypes.API_RESPONSE_ERROR,
    payload: {actionType, error},
});

export const getEventList = (companyId: any): ReportsActionType => ({
    type: ReportActionTypes.GET_EVENT_LIST,
    payload: {action: 'getEvent', ...companyId}
})
export const getReportList = (type: any): ReportsActionType => ({
    type: ReportActionTypes.GET_REPORT_LIST,
    payload: {action: 'getReports', type}
})

export const buildReport = (data: any): ReportsActionType => ({
    type: ReportActionTypes.BUILD_REPORT,
    payload: data
})

export const downloadCsvFile = (s3Key: any): ReportsActionType => ({
    type: ReportActionTypes.DOWNLOAD_CSV,
    payload: {s3Key, action: 'downloadCsv'}
})

export const clearFileUrlEvent = (): ReportsActionType => ({
    type: ReportActionTypes.CLEAR_FILE_URL,
    payload: null
})

export const resetNoDataError = (): ReportsActionType => ({
    type: ReportActionTypes.RESET_DOWNLOAD,
    payload: null
})
