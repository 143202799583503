export enum AccountActionTypes {
    API_RESPONSE_SUCCESS = '@@account/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@account/API_RESPONSE_ERROR',
    RESET = '@@account/RESET',
    RESET_REDIRECT = '@@account/RESET_REDIRECT',

    CREATE_COMPANY = '@@account/CREATE_COMPANY',
    UPDATE_COMPANY = '@@account/UPDATE_COMPANY',

    GET_COMPANY = '@@account/GET_COMPANY',
    GET_ACCOUNT_LINK = '@@account/GET_ACCOUNT_LINK',
    SET_COMPANY = '@@account/SET_COMPANY',

    CHECK_SHOPIFY = '@@account/CHECK_SHOPIFY',
    CONNECT_SHOPIFY = '@@account/CONNECT_SHOPIFY',
    GET_SHOPIFY_PRODUCTS = '@@account/GET_SHOPIFY_PRODUCTS',

    GET_COMPANY_USERS = '@@account/GET_COMPANY_USERS',
    ADD_COMPANY_USER = '@@account/ADD_COMPANY_USER',
    UPDATE_COMPANY_USER = '@@account/UPDATE_COMPANY_USER',
    REMOVE_COMPANY_USER = '@@account/REMOVE_COMPANY_USER',
    CLEAR_COMPANY_USER_FIELDS = '@@account/CLEAR_COMPANY_USER_FIELDS',
}
